<template>
<div>

  <!-- Bulk Assign Jobs -->
  <div class="row">
    <div class="card">
      <div class="card-body">

        <h5 class="card-title">Bulk Assign Jobs</h5>
        <p>To assign jobs enter the job numbers separated by commas. For example: 1000,1001,1002,1003</p>

        <div class="form-group">
          <label for="jobNumbers">Job Numbers</label>
          <input type="text" class="form-control" v-model="jobNumbers" id="jobNumbers">
        </div>

        <button class="btn btn-primary" @click="assignJobs">Assign Jobs</button>

      </div>
    </div>
  </div>

  <div class="row">
    <div class="card">
      <div class="card-body">
  
        <table id="example" class="table table-hover" style="width:100%">
          <thead>
          <tr>
            <th></th>
            <th>#</th>
            <th>Date Created</th>
            <th>Type</th>
            <th style="min-width: 50ch; white-space: normal;">Description</th>
            <th style="min-width: 20ch; white-space: normal;">Town</th>
            <th>Postcode</th>
            <th>Status</th>
            <th style="min-width: 20ch; white-space: normal;">Site</th>
            <th>Client</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(job) in jobs" :key="job.id">
            <td></td>
            <td><a class="link" :href="'/p/jobs/' + job.id">{{ job.id }}</a></td>
            <td>{{ job.created_at }}</td>
            <td>{{ job.type }}</td>
            <td>{{ job.description }}</td>
            <td>{{ job.town }}</td>
            <td>{{ job.postcode }}</td>
            <td>{{ job.realStatus }}</td>
            <td>{{ job.site_name }}</td>
            <td>{{ job.client_name }}</td>
          </tr>
          </tbody>
        </table>
  
      </div>
    </div>
  </div>

</div>
</template>

<script>
import axios from 'axios';

export default {
  name: 'UserJobs',
  props: {
    id: {
      type: Number,
      required: true
    }
  },
  data(){
    return {
      jobs: [],
      jobNumbers: ''
    }
  },
  mounted(){
    this.getJobs();
  },
  methods: {
    getJobs(){
      axios.get(`${process.env.VUE_APP_API_URL}/v1/users/${this.id}/jobs/list`)
      .then(response => {
        response.data.jobs.forEach((job) => {
          switch(job.status){
              case null:
              case 0:
                job.realStatus = 'TBA';
                break;
              case 1:
                job.realStatus = 'Completed';
                break;
              case 2:
                job.realStatus = 'Assigned';
                break;
            }
        });
        this.jobs = response.data.jobs;
      })
      .catch(error => {
        this.$error("Failed to load jobs.", error);
      })
    },
    assignJobs(){
      let jobs = this.jobNumbers.split(',');
      if(jobs.length < 1){
        this.$error("Please enter job numbers to assign.");
        return;
      }

      axios.put(`${process.env.VUE_APP_API_URL}/v1/users/${this.id}/jobs`, {
        job_ids: jobs
      })
      .then(() => {
        this.$success("Assigned jobs to user.");
        this.jobNumbers = "";
      })
      .catch(error => {
        this.$error("Failed to assign jobs to user.", error);
      });
    },
  }
}
</script>